/*eslint-disable*/

const ko = require("knockout");
require("ion-rangeslider");

import { IonRangeSliderEvent } from "ion-rangeslider";

// -----------------------------------------------------------
// Knockout-Bindung für Tooltips
// -----------------------------------------------------------
ko.bindingHandlers.tooltip = {
    init(element: HTMLElement) {

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            const elem = $(element) as JQuery<HTMLElement>;

            if (elem.data("bs.tooltip")) {
                elem.tooltip("dispose");
            }
        });
    },

    update(element: HTMLElement, valueAccessor: () => any) {

        const elem = $(element) as JQuery<HTMLElement>;
        const value = ko.unwrap(valueAccessor());
        const options = ko.toJS(value);

        if (options && options.title) {
            if (elem.data("bs.tooltip")) {
                elem.tooltip("dispose");
            }

            elem.tooltip(options);
        }
    },
};

const defaultPrecision: number = 2;

// -----------------------------------------------------------
// Knockout-Bindung für Prozentwerte
// -----------------------------------------------------------
ko.bindingHandlers.percent = {
    update(
        element: HTMLElement, valueAccessor: () => any, allBindingsAccessor: ko.AllBindings,
        vm: any, ctx: ko.BindingContext<any>) {

        const value = ko.utils.unwrapObservable(valueAccessor());
        const precision =
            ko.utils.unwrapObservable(allBindingsAccessor().precision) ||
            defaultPrecision;
        const formattedValue = (value * 100).toFixed(precision) + " %";

        const updateFunc = ko.bindingHandlers.text.update!;
        updateFunc(element, () => formattedValue);
    },
};

// -----------------------------------------------------------
// Knockout-Bindung für Prozentwerte
// -----------------------------------------------------------
ko.bindingHandlers.progressbar = {
    init(element: HTMLElement, valueAccessor: () => any, allBindingsAccessor: ko.AllBindings,
        vm: any, ctx: ko.BindingContext<any>) {

        const elem = $(element) as JQuery<HTMLElement>;
        const acccessor = valueAccessor();
        const value = acccessor();

        elem.ionRangeSlider({
            from: value,
            onChange: (data: IonRangeSliderEvent) => acccessor(data.from),
        });
    },

    update(element: HTMLElement, valueAccessor: () => any, allBindingsAccessor: ko.AllBindings,
        vm: any, ctx: ko.BindingContext<any>) {

        const elem = $(element) as JQuery<HTMLElement>;
        const data = elem.data("ionRangeSlider");
        const acccessor = valueAccessor();
        const value = acccessor();

        if (data.result.from !== value) {
            data.update({ from: value });
        }
    },
};

// -----------------------------------------------------------
// Knockout-Bindung für Ion-Slider
// -----------------------------------------------------------
ko.bindingHandlers.from = {
    init(element: HTMLElement, valueAccessor: () => ko.Observable<number>) {

        const elem = $(element) as JQuery<HTMLElement>;
        const acccessor = valueAccessor();
        const value = acccessor();

        elem.ionRangeSlider({
            from: value,
            onChange: (data: IonRangeSliderEvent) => acccessor(data.from),
        });
    },
    update(element: HTMLElement, valueAccessor: () => ko.Observable<number>) {

        const elem = $(element) as JQuery<HTMLElement>;
        const data = elem.data("ionRangeSlider");
        const acccessor = valueAccessor();
        const value = acccessor();

        if (data.result.from !== value) {
            data.update({ from: value });
        }
    },
};
