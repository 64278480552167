// -----------------------------------------------------------
// POST-Methode für eine Serveranfrage
// -----------------------------------------------------------
export function postJSON(settings?: JQuery.AjaxSettings) {
    $.ajax({
        contentType: "application/json",
        context: settings!.context,
        data: settings!.data,
        dataType: "json",
        error: settings!.error,
        success: settings!.success,
        type: "POST",
        url: settings!.url,
    });
}

// -----------------------------------------------------------
// GET-Methode für eine Serveranfrage
// -----------------------------------------------------------
export function getJSON(settings?: JQuery.AjaxSettings) {
    $.ajax({
        contentType: settings!.contentType,
        context: settings!.context,
        data: settings!.data,
        dataType: "json",
        error: settings!.error,
        success: settings!.success,
        type: "GET",
        url: settings!.url,
    });
}

export function isNullOrUndefined(value: any) {
    return value === null || value === undefined;
}

export function appendParameter(parameter: any) {
    return isNullOrUndefined(parameter) ? "" : "/" + parameter;
}
