// -----------------------------------------------------------
// Sortierungsaufzählung
// -----------------------------------------------------------
export enum SortDirection {
    Ascending,
    Descending,
}

// -----------------------------------------------------------
// Seitenanfrage Basisklasse
// -----------------------------------------------------------
export class PageRequest {
    public page: number;
    public itemsPerPage: number;
    public sort: SortDirection;
    public sortColumn: string;
    public filter: string;

    public constructor(page: number, itemsPerPage: number, sort: SortDirection, sortColumn: string, filter: string) {
        this.page = page;
        this.itemsPerPage = itemsPerPage;
        this.sort = sort;
        this.sortColumn = sortColumn;
        this.filter = filter;
    }
}
