require("knockout");
const ko = require("knockout");

import { PageItem, Pager } from "./pager";
import { SortDirection } from "./request";

export abstract class GridViewModel<T> {
    public itemsPerPageList: number[];
    public items: ko.ObservableArray<T>;
    public pages: ko.ObservableArray<PageItem>;
    public itemsPerPage: ko.Observable<number>;
    public filter: ko.Observable<string>;
    public lastRecordsPerPage: number;
    public dir: SortDirection | undefined;
    public lastColumn: string;
    public totalCount: ko.Observable<number>;
    public pager: Pager;
    public isready: ko.Observable<boolean>;
    private name: string;

    // -----------------------------------------------------------
    // Konstruktor
    // -----------------------------------------------------------
    constructor(
        name: string, defaultSorting?: string, dir?: SortDirection, itemsPerPage?: number,
        itemsPerPageList?: number[]) {

        this.pager = new Pager();

        this.name = name;
        this.dir = dir ? dir : SortDirection.Ascending;
        this.itemsPerPageList = itemsPerPageList ? itemsPerPageList : [5, 10, 15, 20, 30, 50, 100];
        this.items = ko.observableArray();
        this.pages = ko.observableArray([]);
        this.isready = ko.observable(true);
        this.totalCount = ko.observable(0);
        this.itemsPerPage = ko.observable(itemsPerPage ? itemsPerPage : 5);
        this.lastRecordsPerPage = this.itemsPerPage();
        this.filter = ko.observable("");
        this.lastColumn = defaultSorting ? defaultSorting : "Id";
        // this.dir = SortDirection.Descending;

        this.itemsPerPage.subscribe((value: number) => {
            this.updatePaging();
        }, this);

        this.totalCount.subscribe((value: number) => {
            this.updatePaging();
        }, this);
    }

    public updatePaging = () => {
        this.pages(this.pager.recalcPagination(this.totalCount(), this.itemsPerPage()));
    }

    public setPage = (page: string) => {
        this.pager.setPage(page);
        this.loadData(false);
    }

    public sort = (column: string) => {
        if (this.lastColumn) {
            if (column.toLowerCase() === this.lastColumn.toLowerCase()) {
                if (this.dir === undefined) {
                    this.dir = SortDirection.Ascending;
                } else if (this.dir === SortDirection.Ascending) {
                    this.dir = SortDirection.Descending;
                } else {
                    this.dir = SortDirection.Ascending;
                }
            } else {
                this.dir = SortDirection.Ascending;
            }
        }

        this.lastColumn = column;
        this.loadData(false);
    }

    public loadDataAndReset = (forceSpinner: boolean) => {
        this.pager.page = 0;
        this.loadData(forceSpinner);
    }

    public loadData = (forceSpinner: boolean): void  => undefined;

    public changeFilterByKey = (data: any, event: KeyboardEvent) => {
        if (event.which === 13) {
            this.loadDataAndReset(true);
        }

        return true;
    }

    public changeFilter = () => {
        this.loadDataAndReset(true);
    }

    public changeItemsPerPage = () => {
        this.loadDataAndReset(false);
    }
}
