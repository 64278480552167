// -----------------------------------------------------------
// PageItem-Klasse zur Aktualisierung des Status einer
// Seite oder Navigationselement
// -----------------------------------------------------------
export class PageItem {
    public disabled: boolean;
    public value: string;
    public active: boolean;

    constructor(value: string, disabled: boolean, active: boolean) {
        this.disabled = disabled;
        this.value = value;
        this.active = active;
    }
}

// -----------------------------------------------------------
// Pager-Klasse zur Aktualisierung des Pager-Control
// -----------------------------------------------------------
export class Pager {
    public pageCount: number;
    public page: number;
    public maxVisiblePages: number = 5;
    public FirstPage: string = "|<";
    public PrevPage: string = "<";
    public NextPage: string = ">";
    public LastPage: string = ">|";

    // -----------------------------------------------------------
    // Konstruktor
    // -----------------------------------------------------------
    constructor() {
        this.page = 0;
    }

    // -----------------------------------------------------------
    // Berechnung der anzuzeigenden Seiten und Navigationselemente
    // -----------------------------------------------------------
    public recalcPagination = (totalCount: number, itemsPerPage: number): PageItem[] => {

        const array = Array();

        if (!totalCount) {
            totalCount = 0;
        }

        if (totalCount > 0) {
            this.pageCount = Math.floor((totalCount + itemsPerPage - 1) / itemsPerPage);

            array.push(new PageItem(this.FirstPage, this.page < 1, false));
            array.push(new PageItem(this.PrevPage, this.page < 1, false));

            const visiblePages = Math.min(this.pageCount, this.maxVisiblePages);
            const lastFirstPage = this.pageCount - visiblePages;

            if (this.pageCount > 0) {
                const firstFirstPage = Math.min(Math.max(this.page - Math.floor(visiblePages / 2), 0), lastFirstPage);

                for (let currentPage = firstFirstPage; currentPage < visiblePages + firstFirstPage; currentPage++) {
                    array.push(new PageItem((currentPage + 1).toString(), false, currentPage === this.page));
                }
            }

            const lastPage = lastFirstPage + visiblePages - 2;
            array.push(new PageItem(this.NextPage, this.page > lastPage, false));
            array.push(new PageItem(this.LastPage, this.page > lastPage, false));
        }

        return array;
    }

    // -----------------------------------------------------------
    // Setzte aktuelle Seite
    // -----------------------------------------------------------
    public setPage = (page: string) => {
        switch (page) {
            case this.FirstPage: {
                this.page = 0;
                break;
            }
            case this.PrevPage: {
                this.page = this.page - 1;
                break;
            }
            case this.NextPage: {
                this.page = this.page + 1;
                break;
            }
            case this.LastPage: {
                this.page = this.pageCount - 1;
                break;
            }
            default: {
                this.page = Number(page) - 1;
                break;
            }
        }
    }
}
