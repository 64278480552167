// -----------------------------------------------------------
// Listener
// -----------------------------------------------------------
export type IListener<T> = (event: T) => any;

// -----------------------------------------------------------
// IDisposable
// -----------------------------------------------------------
export interface IDisposable {
    dispose(): void;
}

// -----------------------------------------------------------
// Benutzerdefiniertes Ereignis
// -----------------------------------------------------------
export class TypedEvent<T> {
    private listeners: Array<IListener<T>> = [];
    private listenersOncer: Array<IListener<T>> = [];

    public on = (listener: IListener<T>): IDisposable => {
        this.listeners.push(listener);
        return {
            dispose: () => this.off(listener),
        };
    }

    public once = (listener: IListener<T>): void => {
        this.listenersOncer.push(listener);
    }

    public off = (listener: IListener<T>) => {
        const callbackIndex = this.listeners.indexOf(listener);
        if (callbackIndex > -1) {
            this.listeners.splice(callbackIndex, 1);
        }
    }

    public emit = (event: T) => {
        /** Update any general listeners */
        this.listeners.forEach((listener) => listener(event));

        /** Clear the `once` queue */
        if (this.listenersOncer.length > 0) {
            this.listenersOncer.forEach((listener) => listener(event));
            this.listenersOncer = [];
        }
    }

    public pipe = (te: TypedEvent<T>): IDisposable => {
        return this.on((e) => te.emit(e));
    }
}
